
































import { Component, Prop, Vue } from "vue-property-decorator";
import VideoComponent from "@/components/global/VideoComponent.vue";
import LocalStorageKeys from "@/constants/LocalStorageKeys";

@Component({
    components: {
        VideoComponent
    }
})
export default class VideoSectionComponent extends Vue {
    @Prop() private urls!: string[];

    private visible: boolean = false;

    created() {
        this.updateVisibility();
    }

    private onConsentClick() {
        localStorage.setItem(LocalStorageKeys.ShowYouTubeVideos, "true");
        this.updateVisibility();
    }

    private updateVisibility() {
        this.visible = localStorage.getItem(LocalStorageKeys.ShowYouTubeVideos) != null ? true : false;
    }
}
