










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VideoComponent extends Vue {
    @Prop() private youtubeUrl!: string;
}
