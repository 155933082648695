



















import { Component, Prop, Vue } from "vue-property-decorator";
import { v1 } from "uuid";

@Component
export default class RatingComponent extends Vue {
    @Prop() private selected!: number;
    private uuid: string;

    created() {
        this.uuid = v1();
    }
}
