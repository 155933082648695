
















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ModalComponent from "@/components/global/ModalComponent.vue";
import { SelectComponent, SelectListItem, SelectListItemUtils } from "keiryo";
import UserListDataContext from "@/dataContexts/UserListDataContext";
import RequestResult from "@/common/models/RequestResult";
import Notification from "@/common/Notification";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import { Constants, Recension, UserListEntry } from "@sokkuri/common";

@Component({
    components: {
        ModalComponent,
        SelectComponent
    }
})
export default class BaseUserListEditorComponent extends Vue {
    @Prop() private userListEntry!: UserListEntry;

    private selectableRatings: SelectListItem[] = [];
    private selectableWatchingStates: SelectListItem[] = [];

    private editedUserListEntry: UserListEntry = new UserListEntry();
    protected recension = "";

    mounted() {
        this.selectableRatings = SelectListItemUtils.getItems<number>([ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], (x) => x.toString(), (x) => x.toString());
        this.selectableWatchingStates = SelectListItemUtils.getItems(Constants.UserList.EntryState.AnimeStates, TranslationUtils.translate, (x) => x);

        this.setData();
    }

    @Watch("userListEntry", { deep: true }) onUserListEntryChange() {
        this.setData();
    }

    private setData() {
        this.editedUserListEntry = this.userListEntry;

        this.overrideWatchingStateWithUserlistData();
        this.overrideRatingWithUserlistData();

        this.loadRecensionData().then((x: RequestResult<Recension>) => {
            if (x.successfully && x.data) {
                this.recension = x.data.content;
            }
        });
    }

    private toggleVisibility() {
        const element = this.$refs.modal as ModalComponent;
        element.toggleVisibility();
    }

    private overrideWatchingStateWithUserlistData() {
        SelectListItemUtils.updateSelection(this.selectableWatchingStates, [ this.userListEntry.status ]);
    }

    private overrideRatingWithUserlistData() {
        SelectListItemUtils.updateSelection(this.selectableRatings, [ this.userListEntry.overallRating?.toString() ]);
    }

    private async onSaveClick() {
        const userListSaveResult: RequestResult<void> = await this.saveUserListData();

        if (this.recension != "") {
            const recensionSaveResult: RequestResult<void> = await this.saveRecensionData();

            if (userListSaveResult.successfully && recensionSaveResult.successfully) {
                this.handleSuccessfullySave();
                return;
            }
        }

        if (userListSaveResult.successfully) {
            this.handleSuccessfullySave();
        }
    }

    private async onDeleteClick() {
        const userListEntryDeleteResult: RequestResult<void> = await this.deleteUserListEntry();
        const userRecensionDeleteResult: RequestResult<void> = await this.deleteUserRecension();

        if (userListEntryDeleteResult.successfully && userRecensionDeleteResult.successfully) {
            this.$emit("deleted-entry");
            Notification.addSuccess(TranslationUtils.translate("notification.deletedSuccessfully")).show();
            this.toggleVisibility();
        }
    }

    private handleSuccessfullySave() {
        this.$emit("saved-changes", this.editedUserListEntry);
        Notification.addSuccess(TranslationUtils.translate("notification.savedSuccessfully")).show();
    }

    private async saveUserListData(): Promise<RequestResult<void>> {
        const userListDataContext = new UserListDataContext();

        return userListDataContext.saveChanges(
            this.userListEntry.id,
            this.editedUserListEntry.overallRating,
            this.editedUserListEntry.status,
            this.editedUserListEntry.currentEpisode);
    }

    protected async saveRecensionData(): Promise<any> {}
    protected async loadRecensionData(): Promise<any> {}
    protected async deleteUserListEntry(): Promise<any> {}
    protected async deleteUserRecension(): Promise<any> {}
}
