






























































































































import { Component, Vue } from "vue-property-decorator";
import AnimeDataContext from "@/dataContexts/AnimeDataContext";
import _ from "lodash";
import ImageComponent from "@/components/global/ImageComponent.vue";
import DescriptionComponent from "@/components/entry/DescriptionComponent.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import Notification from "@/common/Notification";
import UserListDataContext from "@/dataContexts/UserListDataContext";
import RequestResult from "@/common/models/RequestResult";
import { UserSessionManager } from "kogitte";
import InfoCardComponent from "@/components/entry/InfoCardComponent.vue";
import KeyValuePair from "@/common/models/KeyValuePair";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import VideoComponent from "@/components/global/VideoComponent.vue";
import ContentDataContext from "@/dataContexts/ContentDataContext";
import AnimeGridComponent from "@/components/global/grid/AnimeGridComponent.vue";
import EntryUtils from "@/common/utilities/EntryUtils";
import AnimeUserListEditorComponent from "@/components/global/userListEditor/AnimeUserListEditorComponent.vue";
import { SelectComponent, SelectListItem, SelectListItemUtils } from "keiryo";
import RecensionComponent from "@/components/entry/RecensionComponent.vue";
import RecensionViewModel from "@/common/viewModels/RecensionViewModel";
import RecensionDataContext from "@/dataContexts/RecensionDataContext";
import { Constants, EntryTitle, Description, Anime, UserListEntry, Tag } from "@sokkuri/common";
import SeoUtils from "@/common/utilities/SeoUtils";
import Settings from "@/Settings";
import Routes from "@/router/Routes";
import VideoSectionComponent from "@/components/entry/VideoSectionComponent.vue";

@Component({
    components: {
        ImageComponent,
        DescriptionComponent,
        SpinnerComponent,
        InfoCardComponent,
        VideoComponent,
        AnimeGridComponent,
        AnimeUserListEditorComponent,
        SelectComponent,
        RecensionComponent,
        VideoSectionComponent
    }
})
export default class AnimeView extends Vue {
    private loading = false;
    private animeDataContext: AnimeDataContext = new AnimeDataContext();
    private userListDataContext: UserListDataContext = new UserListDataContext();
    private contentDataContext: ContentDataContext = new ContentDataContext();
    private recensionDataContext: RecensionDataContext = new RecensionDataContext();

    private anime: Anime = new Anime();
    private videoUrls: string[] = [];
    private userListEntry: UserListEntry = new UserListEntry();
    private entryMainTitle: EntryTitle = new EntryTitle();
    private entryMainDescription: Description = new Description();
    private entryTags: Tag[] = [];
    private entryDetails: Array<KeyValuePair<string, string>> = [];
    private additionalEntryInfos: KeyValuePair<string, string[]>[] = [];
    private similiarAnimes: Anime[] = [];
    private recensions: RecensionViewModel[] = [];

    private selectableRatings: SelectListItem[] = [];
    private selectableWatchingStates: SelectListItem[] = [];
    private selectedValue = "";

    created() {
        const animeId: number = +this.$route.params.id;

        this.selectableRatings = SelectListItemUtils.getItems<number>([ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], (x) => x.toString(), (x) => x.toString());
        this.selectableWatchingStates = SelectListItemUtils.getItems(Constants.UserList.EntryState.AnimeStates, TranslationUtils.translate, (x) => x);

        if (animeId) {
            this.loading = true;

            const getAnime = this.animeDataContext.getAnime(animeId);
            getAnime.then(x => {
                if (x.successfully && x.data) {
                    this.anime = x.data;

                    this.entryMainTitle = EntryUtils.getTitle(this.anime.titles);
                    this.setMainDescription();
                    this.setEntryDetails();
                    this.setAdditionalInformation();
                    this.setUserListData();
                    this.setSeoInfo();
                }
            });

            const getContents = this.contentDataContext.getAnimeContents(animeId);
            getContents.then(x => {
                if (x.successfully && x.data) {
                    this.videoUrls = x.data.filter(x => x.type == Constants.ContentTypes.Video).map(x => x.url);
                }
            });

            const getAllAnimeRecensions = this.recensionDataContext.getAnimeRecensions(animeId);
            getAllAnimeRecensions.then(x => {
                if (x.successfully && x.data) {
                    this.recensions = x.data;
                }
            });

            Promise.all([ getAnime, getContents, getAllAnimeRecensions ]).finally(() => this.loading = false);

            this.animeDataContext.getSimilarAnimes(animeId).then((similiarAnimesResult: RequestResult<Anime[]>) => {
                if (similiarAnimesResult.successfully && similiarAnimesResult.data) {
                    this.similiarAnimes = similiarAnimesResult.data;
                }
            });
        }
    }

    private setSeoInfo() {
        // Add searchengine metadata
        SeoUtils.updateTitle(this.entryMainTitle.title);
        SeoUtils.updateMeta("description", this.entryMainDescription.content);

        // Add open graph metadata
        SeoUtils.updateMeta("og:site_name", Settings.Name);
        SeoUtils.updateMeta("og:title", this.entryMainTitle.title);
        SeoUtils.updateMeta("og:image", `https://sokkuri.eu${Settings.FilesUrl}${this.anime.systemFile.name}`);
        SeoUtils.updateMeta("og:url", `https://sokkuri.eu${Routes.Anime.Anime(this.anime.id)}`);
        SeoUtils.updateMeta("og:description", this.entryMainDescription.content);

        // Add twitter card metadata
        SeoUtils.updateMeta("twitter:card", "summary");
    }

    private setMainDescription() {
        if (this.anime.descriptions && this.anime.descriptions.length > 0) {
            this.entryMainDescription = this.anime.descriptions
                .some(x => x.language == Constants.Languages.German) ?
                    (this.anime.descriptions.find(x => x.language == Constants.Languages.German) as Description) : (_.first(this.anime.descriptions) as Description);
        }
    }

    private setEntryDetails() {
        if (this.anime) {
            let details: Array<KeyValuePair<string, string>> = [
                new KeyValuePair<string, string>({
                    key: "anime.details.format",
                    value: TranslationUtils.translate(this.anime.type) }),
                new KeyValuePair<string, string>({
                    key: "anime.details.state",
                    value: TranslationUtils.translate(this.anime.status) }),
                new KeyValuePair<string, string>({
                    key: "anime.details.episodes",
                    value: this.anime.episodes.toString() }),
                new KeyValuePair<string, string>({
                    key: "anime.details.adaption",
                    value: TranslationUtils.translate(this.anime.adaptation) }),
                new KeyValuePair<string, string>({
                    key: "anime.details.airedSeason",
                    value: `${TranslationUtils.translate(this.anime.airedSeason)} ${this.anime.airedYear}` }),
            ];

            if (this.anime.endingSeason && this.anime.endingYear) {
                details.push(new KeyValuePair<string, string>({
                    key: "anime.details.endingSeason",
                    value: `${TranslationUtils.translate(this.anime.endingSeason)} ${this.anime.endingYear}` })
                );
            }

            details.forEach((x: KeyValuePair<string, string>) => {
                x.key = TranslationUtils.translate(x.key);
            });

            this.entryDetails = this.entryDetails.concat(details);
        }
    }

    private setUserListData() {
        // Only load the userlist date when there is a session.
        new UserSessionManager().getCurrentSession().then((session => {
            if (session) {
                this.userListDataContext.getAnimeEntry(this.anime.id).then((userListResult: RequestResult<UserListEntry>) => {
                    if (userListResult.successfully && userListResult.data) {
                        this.userListEntry = userListResult.data;

                        this.overrideRatingWithUserlistData();
                        this.overrideWatchingStateWithUserlistData();
                    }
                });
            }
        }));
    }

    private clearUserListData() {
        this.selectableRatings = SelectListItemUtils.getItems<number>([ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], (x) => x.toString(), (x) => x.toString());
        this.selectableWatchingStates = SelectListItemUtils.getItems(Constants.UserList.EntryState.AnimeStates, TranslationUtils.translate, (x) => x);
        this.userListEntry = new UserListEntry();
    }

    private setAdditionalInformation() {
        let infos: KeyValuePair<string, string[]>[] = [];

        const otherTitles = this.anime.titles.filter(x => x != this.entryMainTitle && x.type == Constants.EntryTitleTypes.Title);

        otherTitles.forEach(x => {
            infos.push(new KeyValuePair<string, string[]> ({
                key: x.language,
                value: [ x.title ]
            }));
        });

        const synonyms = this.anime.titles.filter(x => x.type == Constants.EntryTitleTypes.Synonym)?.map(x => x.title);

        if (synonyms && synonyms.length > 0) {
            infos.push(new KeyValuePair<string, string[]>({
                key: "anime.heading.synonyms",
                value: synonyms
            }));
        }

        const companyTitles = this.anime.companies.map(x => x.name);

        if (companyTitles && companyTitles.length > 0) {
            infos.push(new KeyValuePair<string, string[]>({
                key: "anime.heading.companies",
                value: companyTitles
            }));
        }

        this.additionalEntryInfos = this.additionalEntryInfos.concat(infos);
    }

    private overrideRatingWithUserlistData() {
        SelectListItemUtils.updateSelection(this.selectableRatings, [ this.userListEntry.overallRating?.toString() ]);
    }

    private overrideWatchingStateWithUserlistData() {
        SelectListItemUtils.updateSelection(this.selectableWatchingStates, [ this.userListEntry.status ]);
    }

    private saveUserListWatchingState(value: string): void {
        if (value) {
            this.userListDataContext.setAnimeState(this.anime.id, value).then((x: RequestResult<void>) => {
                if (x.successfully) {
                    this.setUserListData();
                    this.showSuccessfullySaveNotification();
                }
            });
        }
    }

    private saveUserListRating(value: number): void {
        if (value) {
            this.userListDataContext.setAnimeRating(this.anime.id, value).then((x: RequestResult<void>) => {
                if (x.successfully) {
                    this.setUserListData();
                    this.showSuccessfullySaveNotification();
                }
            });
        }
    }

    private onAddAnimeToListClick() {
        this.userListDataContext.addAnime(this.anime.id).then((x: RequestResult<void>) => {
            if (x.successfully) {
                this.setUserListData();
                this.showSuccessfullySaveNotification();
            }
        });
    }

    private showSuccessfullySaveNotification() {
        Notification.addSuccess(TranslationUtils.translate("notification.savedSuccessfully")).show();
    }
}
