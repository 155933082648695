











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InfoCardComponent extends Vue {
    @Prop() private title!: string;
    @Prop() private content!: string;
}
