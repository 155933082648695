


















import { Component, Prop } from "vue-property-decorator";
import BaseEntryGrid from "@/components/global/grid/BaseEntryGrid";
import { Anime } from "@sokkuri/common";
import AnimeTooltip from "@/common/tooltips/AnimeTooltip";
import GridCardComponent from "@/components/global/grid/GridCardComponent.vue";

@Component({
    components: {
        GridCardComponent
    }
})
export default class AnimeGridComponent extends BaseEntryGrid {
    @Prop() protected entries: Anime[];

    protected entryType = "anime";

    protected initTooltips() {
        const elements = this.$el.querySelectorAll("div.grid-card-component div.tooltip");

        if (elements) {
            new AnimeTooltip(this.entries).createMany(elements);
        }
    }
}
