












import { Component, Prop, Vue } from "vue-property-decorator";
import RatingComponent from "@/components/entry/RatingComponent.vue";

@Component({
    components: {
        RatingComponent
    }
})
export default class RecensionComponent extends Vue {
    @Prop() private username: string;
    @Prop() private stars: number;
    @Prop() private content: string;
}
